*::-webkit-scrollbar {
    width: 8px;
  }
  
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #f0f0f0;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;

  }
  